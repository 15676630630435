import React, { FunctionComponent } from "react"
import Layout from "../layout/index"
import schema from "./index-schema.json"

import "./index.css"

const IndexPage: FunctionComponent<Record<string, unknown>> = () => (
  <Layout
    lang="de"
    title="codaline - Remote Softwareentwicklung"
    description="Remote Softwareentwicklung, Beratung, Prototyping & Code Audits"
    microdata={JSON.stringify(schema)}
  >
    <header>
      <div>
        <img src="logo.png" className="logo" alt="codaline logo" />
        <div>
          <div className="we-are">Wir sind codaline</div>
          <h1>
            Remote Softwareentwicklung, Beratung, Prototyping & Code Audits
          </h1>
        </div>
      </div>
    </header>

    <main>
      <section className="intro-section">
        <div className="row">
          <div className="column light-orange-background">
            <div>
              <img
                src="bengt_marcus.png"
                className="bengt-marcus-image"
                alt="bengt and marcus"
              />
            </div>
          </div>
          <div className="column">
            <div>
              <h2>Ein junges Team aus Software Developern</h2>
              <p>
                Wer wir sind? Wir sind Bengt und Marcus - 2 Software Entwickler
                aus Thüringen, die in den letzten Jahren in sowohl größeren
                Firmen als auch im kleineren Agentur- und Beratungsgeschäft viel
                Know-How und Erfahrung erlangt haben. Wir legen sehr viel Wert
                auf eine professionelle Zusammenarbeit mit unseren Kunden,
                schreiben soliden und ausgiebig getesteten Programmcode und
                begleiten Produkte gern von der Idee bis zur Markteinführung.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="tech-section">
        <div className="row">
          <div className="column">
            <div>
              <h2>Was wir machen?</h2>
              <p>
                Wir beide haben jahrelange Erfahrungen im Bereich der
                Softwareentwicklung. Dort vor allem in der Web- und hybriden
                App-Entwicklung. Dabei konnten wir viele Technologien, ob
                server- oder clientseitig, ausprobieren und kennenlernen.
              </p>
              <p>
                Jedoch sind andere Technologien kein Fremdbegriff. Wir stellen
                gern, falls nicht vorgegeben, auch projekt- bzw. problembezogen
                die passenden Programmiersprachen und Frameworks zusammen.
                Bieten sich beispielsweise Micro-Services an, so kann je nach
                Aufgabe des Services die Sprache unterschiedlich sein.
              </p>
            </div>
          </div>
          <div className="column orange-background">
            <div>
              <h3>Tech-Stack</h3>
              <div className="tech-category">
                <img
                  src="frontend.png"
                  className="frontend-icon"
                  alt="frontend icon"
                />
                <div>
                  <h4>Frontend</h4>
                  <ul>
                    <li>React, Vue, Angular, Ionic</li>
                    <li>SCSS, SASS, CSS</li>
                    <li>TypeScript</li>
                    <li>natives JavaScript</li>
                  </ul>
                </div>
              </div>
              <div className="tech-category">
                <img
                  src="backend.png"
                  className="backend-icon"
                  alt="backend icon"
                />
                <div>
                  <h4>Backend</h4>
                  <ul>
                    <li>
                      NodeJS, z.B. in Kombination mit NuxtJS (Node + Vue + SSR)
                      oder NextJS (Node + React + SSR)
                    </li>
                    <li>REST und GraphQL für moderne APIs</li>
                    <li>Postgresql, MongoDB</li>
                    <li>TypeORM als ORM-Layer</li>
                    <li>TypeScript</li>
                    <li>PHP, Python, Rails</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="remote-section">
        <div className="row">
          <div className="column">
            <div className="red-background">
              <h3>Remote</h3>
              <img src="remote.png" className="remote-icon" alt="remote icon" />
            </div>
          </div>
          <div className="column">
            <div>
              <h2>Warum arbeiten wir nur Remote?</h2>
              <p>
                In den letzten Jahren konnten wir durch unsere moderne und
                hauptsächlich remote stattfindende Arbeit, Familien gründen und
                sesshaft werden. Diese Arbeitsweise wollen wir möglichst auch in
                Zukunft beibehalten, denn uns ist unser Thüringen, unsere
                Familie und Freundschaften sehr wichtig. Daher sind wir
                vorrangig auf der Suche nach Projekten, Partnern und Kunden, die
                mit Remote-Tätigkeit kein Problem haben. Wir wissen natürlich,
                dass dies nicht immer möglich ist, und ein persönliches
                Kennenlernen oft wichtig ist.
              </p>
              <p>
                Jedoch sind wir, wie wir sind. Ob über die Webcam oder in
                Persona - wir verstellen uns nicht.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio-section orange-background">
        <div className="row">
          <div className="column">
            <div>
              <h2>
                Was haben wir <br /> bisher gemacht?
              </h2>
            </div>
          </div>
          <div className="column">
            <div>
              <ul>
                <li>
                  Umsetzung und Konzeption zahlreicher hybrider Apps auf Basis
                  von Stencil, React und Angular mit NodeJS-Backends
                </li>
                <li>
                  Kleinere Desktop-Anwendungen mit ElectronJS für Windows und
                  Mac
                </li>
                <li>
                  Nutzung von VueJS zur Erstellung dynamischer Webseiteninhalte, z.B. Produkt-Konfiguratoren
                </li>
                <li>
                  React (auch in Kombination mit NextJS) für moderne
                  Webanwendungen
                </li>
                <li>Wartung und Weiterentwicklung von RubyOnRails-Projekten</li>
                <li>
                  Erstellung kleinerer APIs mit PHP und Python, um auch auf
                  einfachen Webspaces nutzbar zu sein
                </li>
                <li>OpenSource-Software</li>
                <li>
                  Schulungen und Beratungen im Bereich der modernen
                  Webtechnologien, insbesondere im Thema Angular & Ionic
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-section">
        <div className="row">
          <div className="column">
            <div className="red-background">
              <h3>Wie kannst du uns kontaktieren?</h3>
              <h2>
                <a href="mailto:mail@codaline.io">per E-Mail</a>
              </h2>
            </div>
          </div>
          <div className="column">
            <div className="orange-background">
              <h2>Kontaktformular</h2>
              <form
                method="post"
                data-netlify="true"
                netlify-honeypot="bot-field"
                name="contact"
              >
                <input name="bot-field" hidden />
                <input
                  required
                  placeholder="Name"
                  type="text"
                  name="name"
                  id="name"
                />
                <input
                  required
                  placeholder="E-Mail"
                  type="email"
                  name="email"
                  id="email"
                />
                <input
                  required
                  placeholder="Betreff"
                  type="text"
                  name="topic"
                  id="topic"
                />
                <textarea
                  required
                  placeholder="Nachricht"
                  name="message"
                  id="message"
                  rows={5}
                />
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default IndexPage
